import "./joinVolunteer.css"
import MemberCard from "../components/MemberCard"
import DanielImg from "../assets/images/Daniel Ose.png"
import unknown1 from "../assets/images/20230110_092831.png"
import unknown2 from "../assets/images/Professional Portrait.png"
import unknown3 from "../assets/images/_NPS4748.png"

import BraimahImg from "../assets/images/Braimah.png"
import FaithImg from "../assets/images/Faith.png"
import ConfidenceImg from "../assets/images/Confidence.png"
import DavidImg from "../assets/images/David.png"
import ErnestImg from "../assets/images/Ernest.png"
import AmaraImg from "../assets/images/Amara 1.png"
import UnityImg from "../assets/images/Unity.png"


const Teams = () => {
    return (
        <div className="join-volunteer">
            <div className="jv-banner team-banner flex-col">
                <h2>Meet the <span className="yellow-text">Team</span></h2>
                <div className="yellow-bar"></div>
                <a href="mailto:hello@edotech.com.ng" className="btn-join flex-col">Let's Talk</a>
            </div>
            <div className="members flex-col">
                <div className="executives">
                    <div className="head">
                        <h3>The Executives</h3>
                        <div className="jv-bar"></div>
                    </div>

                    <div className="list">
                        <MemberCard name="Daniel Ose" position="Content Lead" img={DanielImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Chioma Obiokolo" position="Community Manager" img={unknown1} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Emmanuel Onotiemoria" position="Volunteer Manager" img={unknown2} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Teddy Ofure Eragbai" position="Chief Operations Officer" img={unknown3} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                    </div>
                </div>

                <div className="work-force">
                    <div className="head">
                        <h3>Workforce</h3>
                        <div className="jv-bar"></div>
                    </div>

                    <div className="list">
                        <MemberCard name="Unity Ekeoba" position="Web Developerr" img={UnityImg} linkedin="https://linkedin.com/in/unity-ekeoba-2a68a9259/" website="https://devroyale.vercel.app" />
                        <MemberCard name="David Onotiemoria" position="UI UX/Graphics Designer" img={DavidImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Faith Iguma-Asaka" position="Social media content Editor" img={FaithImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Confidence Nkereuwem" position="Video Editor/Graphics Designer" img={ConfidenceImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Braimah Fadilat" position="Operations Associate" img={BraimahImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Ernest Imaguomwanruo" position="Graphics Designer" img={ErnestImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                        <MemberCard name="Ndukwe Amarachi" position="Content Writer" img={AmaraImg} linkedin="http://linkedin.com/company/edotechcommunity" website="http://instagram.com/tech_in_edo" />
                    </div>
                </div>
            </div>

            <div className="jv-card flex-col">
                <p> Would you like to help us build the future of Edo's tech ecosystem?</p>
                <p>
                    We are always on the look out for passionate and commited people who
                    want to leave a mark in the Edo tech scene to join our team.
                </p>
                <p>If that is you, apply below.</p>
                <button className="btn-join">Come Join Us</button>
            </div>
        </div>
    )
}

export default Teams